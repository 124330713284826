<template>
  <div>
    <!--Filtros-->
    <suits-filter-card v-model="filter" session-key="managed"/>

    <!--Colunas-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>{{$t('columns.title')}}</h5>
      </b-card-header>
      <b-card-body>
        <div class="text-center">
          <b-form-checkbox inline v-for="column in columns._options"
                           v-bind:id="`column-${column.key}`" v-bind:name="`column-${column.key}`"
                           v-model="columns[column.key]"
          >
            {{ $t(`columns.${column.label}`) }}
          </b-form-checkbox>
        </div>

      </b-card-body>
    </b-card>

    <!--Table-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>Processos</h5>
      </b-card-header>
      <b-card-body>
        <suits-table ref="suitsTable" v-bind:filters="filter" v-bind:fields="columns" @selected="onSuitSelected">
          <template #table-top-actions="{selectedIds}">
            <div class="w-100 mb-1 ml-2" v-if="Object.keys(selectedIds).length > 0">
              <b-button size="sm" class="ml-1 d-inline-block" style="width: 250px" variant="outline-success">
                <i class="fa-solid fa-check"></i>
                {{$t('suitAction.sendToRevision.full')}}
              </b-button>
              <b-button size="sm" class="ml-1 d-inline-block" style="width: 250px" variant="outline-danger">
                <i class="fa-solid fa-xmark"></i>
                {{$t('suitAction.discard.full')}}
              </b-button>
            </div>
          </template>
        </suits-table>
      </b-card-body>
    </b-card>
    <suit-managed-modal ref="suit-modal"></suit-managed-modal>
  </div>
</template>

<script>
import SuitFilter from '@/model/SuitFilterRequest'
import mixinSuitViewBase from '@/components/mixin/mixinSuitViewBase'

export default {
  name: 'MonitoringSuits',
  mixins:[mixinSuitViewBase],
  data: () => ({
    filter: {
      ...SuitFilter(),
      monitoringStep: ['MANAGED']
    },
    columns: {
      id: false, lastUpdate: true, complainant: true, defendant: true, section: true, subsection: true, sentence: true, sentenceAmount: true, keyWords: true, points: true,
      _options: [
        { key: 'id', label: 'id' }, { key: 'lastUpdate', label: 'lastUpdate' }, { key: 'section', label: 'section' },
        { key: 'subsection', label: 'subsection' }, { key: 'complainant', label: 'complainant' }, { key: 'defendant', label: 'defendant' },
        { key: 'sentence', label: 'sentence' }, { key: 'sentenceAmount', label: 'sentenceEstimatedAmount' }, { key: 'keyWords', label: 'keyWordsTotal' },
        { key: 'points', label: 'points' },]
    }
  }),
}
</script>

<style scoped>

</style>
